import React, { useEffect } from "react";
import "./App.css";
import store from "./utils/store";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import Routes from "./utils/routes";
import { isMobile } from "react-device-detect";

function App() {
	useEffect(() => {
		if (isMobile) {
			window.location.href = "https://site.negraz.com";
		}
	}, []);

	return (
		<Provider store={store}>
			<Router>
				<Routes />
			</Router>
		</Provider>
	);
}

export default App;
