import ResumePDF from "./Oussama_EL_Negraz_CV.pdf";
import default_avatar from "../../assets/images/baseImages/avatar.png";
import FSmedia from ".././../assets/images/data/FSmedia.png";
import CreativeKeys from ".././../assets/images/data/CreativeKeys.png";
import Naja7Host from ".././../assets/images/data/Naja7Host.png";

const user = {
	firstName: "Oussama",
	lastName: "El Negraz",
	userImage: default_avatar,
	resume: ResumePDF,
	email: "oussama.elnegraz@etu.uae.ac.ma",
	linkedIn: "in/Oussama-ELNegraz",
	gitHub: "Oussama-ELNegraz",
	twitter: null,  // Not provided
	aboutMe: {
		intro: "I am a 5th-year Computer Engineering student at ENSA Tetouan.",
		description:
			"I am passionate about emerging technologies and solving complex challenges. I have strong skills in programming, web development, and project management. I’m always eager to learn and explore new professional growth opportunities.",
		outro: "When I’m not coding, I enjoy traveling, fitness, and graphic design.",
	},
	experiences: [
		{
			organization: "FSmedia.ma",
			organizationPicture: FSmedia,
			isCurrent: true,
			startDate: "2023",
			endDate: null,
			positions: [
				{
					positionName: "Auto Entrepreneur",
					startDate: "2023",
					endDate: "Present",
					isPresent: true,
					description:
						"Actively developing custom software solutions. Gained valuable skills in software development and accounting practices.",
				},
			],
		},
		{
			organization: "CreativeKeys",
			organizationPicture: CreativeKeys,
			isCurrent: false,
			startDate: "2022",
			endDate: "2023",
			positions: [
				{
					positionName: "Full Stack Developer Intern",
					startDate: "2022",
					endDate: "2023",
					isPresent: false,
					description:
						"Developed custom software solutions for businesses, specializing in software development and accounting.",
				},
			],
		},
		{
			organization: "Naja7Host",
			organizationPicture: Naja7Host,
			isCurrent: false,
			startDate: "Jan 2022",
			endDate: "Mar 2022",
			positions: [
				{
					positionName: "Frontend Developer Intern",
					startDate: "Jan 2022",
					endDate: "Mar 2022",
					isPresent: false,
					description:
						"Contributed to creating digital solutions, enhancing companies' online presence through web development.",
				},
			],
		},
	],
	education: [
		{
			instituteName: "École Nationale des Sciences Appliquées de Tétouan",
			degree: "Engineering Degree in Computer Science",
			year: "2025 (Expected)",
			id: 1,
		},
		{
			instituteName: "Hassan 2 High School, Tetouan",
			degree: "Baccalauréat in Mathematical Sciences",
			year: "2015",
			id: 2,
		},
	],
	projects: [
		{
			projectName: "Professional Platform",
			description:
				"Developed a Laravel-based platform enabling professionals to find freelancers and collaborate effectively through a user-friendly interface.",
			madeWith: ["MySQL", "Laravel", "Bootstrap", "HTML", "CSS", "JavaScript"],
			link: "https://www.negraz.com/projects/professional-platform",
		},
		{
			projectName: "School Document Management App",
			description:
				"Created a MERN application for managing school certificates and grades, streamlining administrative processes for students and educators.",
			madeWith: ["MongoDB", "Express.js", "React", "Node.js"],
			link: "www.negraz.com/projects/school-document-app",
		},
		{
			projectName: "Delivery Tracking Application",
			description:
				"Developed a Laravel and Vue.js application for tracking deliveries, offering a smooth user experience for monitoring shipments.",
			madeWith: ["MySQL", "Laravel", "Vue.js", "Bootstrap"],
			link: "www.negraz.com/projects/delivery-tracking",
		},
	],
	skills: [
		{
			name: "Language Proficiency",
			values: [
				"HTML",
				"CSS",
				"JavaScript",
				"React.js",
				"Node.js (Express.js)",
				"PHP",
				"Laravel",
				"MySQL",
				"MongoDB",
				"PLSQL",
				"Java",
				"C",
				"Python",
			],
		},
		{
			name: "Tools/IDE Proficiency",
			values: ["Linux", "Git", "GitHub", "Adobe Canva"],
		},
		{
			name: "Database Proficiency",
			values: ["MySQL", "MongoDB", "PLSQL"],
		},
	],
};

export default user;
