import { initializeApp, getApps } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import projectConfig from "../utils/data/project.config";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_APIKEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

console.log(firebaseConfig);

let app;

// Initialize Firebase only if no other instance exists
if (!getApps().length) {
	app = initializeApp(firebaseConfig);

	// Enable analytics if the project configuration allows it
	if (projectConfig.enableAnalytics) {
		isSupported().then((supported) => {
			if (supported) {
				getAnalytics(app);
			}
		});
	}
}

export default app;
